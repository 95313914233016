import * as React from 'react';
import { graphql, Link } from 'gatsby';
import Img, { FluidObject } from 'gatsby-image';
import { format } from 'date-fns';
import { BlogPosting } from 'schema-dts';
import { JsonLd } from 'react-schemaorg';

import { PostPagesQuery } from '../../graphql-types';
import { Container } from '../components/Container';
import { Layout } from '../components/Layout';
import { Section } from '../components/Section';
import { RichText } from '../components/RichText';
import SEO from '../components/seo';
import styles from './post.module.css';

interface PostPagesProps {
  data: PostPagesQuery;
}

export const query = graphql`
  query PostPages($id: String!) {
    site {
      siteMetadata {
        organization {
          legalName
        }
      }
    }

    contentfulPost(id: { eq: $id }) {
      id
      slug
      title
      date
      author {
        name
      }
      body {
        json
      }
      image {
        title
        description
        localFile {
          childImageSharp {
            fluid(toFormat: JPG, maxWidth: 1076) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      updatedAt
    }
  }
`;

export default ({ data }: PostPagesProps) => {
  const p = data.contentfulPost;
  const bodyJson = p?.body?.json;
  const title = p?.title as string;
  const heroImage = p?.image;

  return (
    <Layout>
      <SEO
        title={title}
        meta={[
          {
            property: 'og:image',
            content: heroImage?.localFile?.childImageSharp?.fluid?.src,
          },
          {
            name: 'twitter:image',
            content: heroImage?.localFile?.childImageSharp?.fluid?.src,
          },
        ]}
      />

      <Section>
        <Container>
          <article
            className={styles.article}
            data-post={data.contentfulPost?.id}
          >
            <JsonLd<BlogPosting>
              item={{
                '@context': 'https://schema.org',
                '@type': 'BlogPosting',
                datePublished: p?.date,
                publisher: {
                  '@type': 'Organization',
                  name: data.site?.siteMetadata?.organization
                    ?.legalName as string,
                  logo: {
                    '@type': 'ImageObject',
                    contentUrl: '/static/images/logo.png',
                    url: '/static/images/logo.png',
                  },
                },
                image: heroImage?.localFile?.childImageSharp?.fluid?.src,
                author: p?.author?.name as string,
                headline: title,
                dateModified: p?.updatedAt,
              }}
            />
            <header className={styles.header}>
              <h1 className={styles.heading}>{title}</h1>

              <div className={styles.details}>
                Posted on{' '}
                <time dateTime={p?.date as string}>
                  {format(new Date(p?.date as string), 'MMM d, yyyy')}
                </time>{' '}
                by <span>{p?.author?.name}</span>
              </div>
            </header>

            {heroImage ? (
              <figure className={styles.heroImage}>
                <Img
                  fluid={
                    heroImage.localFile?.childImageSharp?.fluid as FluidObject
                  }
                  alt={heroImage?.description as string | undefined}
                  title={heroImage.title as string}
                />
                {heroImage.description && (
                  <figcaption className={styles.heroCaption}>
                    {heroImage.description}
                  </figcaption>
                )}
              </figure>
            ) : null}

            <div className={styles.body}>
              <RichText json={bodyJson} />
            </div>
          </article>

          <div className={styles.back}>
            <Link to="/blog">Back to Blog</Link>
          </div>
        </Container>
      </Section>
    </Layout>
  );
};
